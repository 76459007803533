// src/components/MainLayout.js
import React from 'react';
import { CustomNavbar } from '../pages/Navbar';
import { Footer } from '../pages/Footer';


const MainLayout = ({ children }) => {
  return (
    <div className="main-layout">
      <div className='main'>
        <CustomNavbar />
        {children}

        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
