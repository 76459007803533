import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";

import stream1 from "../assets/images/stream_thumb01.jpg";
import stream2 from "../assets/images/stream_thumb02.jpg";
import stream3 from "../assets/images/stream_thumb03.jpg";
import Play from "../assets/images/w_play.png";

export const LiveStreaming = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>


            <section className="live-streaming-area live_stream_page">
                <Container>
                    <Row>
                        <Col>
                            <div className="hf-section-title mb-50">
                                <h4 className="title">live streaming</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={8}>
                            <div className="live-stream-item mb-30">
                                <div className="thumb">
                                    <img src={stream1} alt="" className="main-img" />
                                    <a href="#" className="popup-video">
                                        <img src={Play} alt="" />
                                    </a>
                                </div>
                                <div className="content">
                                    <div className="live-stream-meta">
                                        <ul>
                                            <li className="cat">
                                                <a href="#">CRICKET</a>
                                            </li>
                                            <li>3 months ago</li>
                                        </ul>
                                    </div>
                                    <h4 className="title">
                                        <a href="#">
                                            Ditched The Naked Chicken Chalupa Here’s To Make
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col md={12}>
                                    <div className="live-stream-item style-two mb-30">
                                        <div className="thumb">
                                            <img src={stream2} alt="" />
                                        </div>
                                        <div className="content">
                                            <a href="#" className="popup-video">
                                                <img src={Play} alt="" />
                                            </a>
                                            <div className="live-stream-meta">
                                                <ul>
                                                    <li className="cat">
                                                        <a href="#">sports</a>
                                                    </li>
                                                    <li>3 months ago</li>
                                                </ul>
                                            </div>
                                            <h4 className="title">
                                                <a href="#">House last week that move would Inject</a>
                                            </h4>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="live-stream-item style-two mb-30">
                                        <div className="thumb">
                                            <img src={stream3} alt="" />
                                        </div>
                                        <div className="content">
                                            <a
                                                href="https://www.youtube.com/watch?v=ssrNcwxALS4"
                                                className="popup-video">
                                                <img src="img/icon/w_play.png" alt="" />
                                            </a>
                                            <div className="live-stream-meta">
                                                <ul>
                                                    <li className="cat">
                                                        <a href="#">sports</a>
                                                    </li>
                                                    <li>3 months ago</li>
                                                </ul>
                                            </div>
                                            <h4 className="title">
                                                <a href="#">Twas the night before in the NFL</a>
                                            </h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
};
