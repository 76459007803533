import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import Titlebar from "../assets/images/title_bar02.png";
import slide1 from "../assets/images/latest_games_thumb01.jpg";
import slide2 from "../assets/images/latest_games_thumb02.jpg";
import slide3 from "../assets/images/latest_games_thumb03.jpg";
import Slider from "react-slick";


export const LatestReleases = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>  <section className="about_wrapper">
            <Container>

                <Row className="align-items-center mt-5">
                    <Col md={12} className="text-center main_heading mb-0 mt-5">
                        <h3>
                            RELEASED <span>GAMES</span>
                        </h3>
                        <p>
                            Compete with 100 players on a remote island for winner takes
                            showdown <br />
                            known issue where certain skin strategic
                        </p>
                        <img src={Titlebar} />
                    </Col>
                    <Col md={12}>
                        <Slider {...settings} className="main_slider mt-5">
                            <div className="slider-item">
                                <div className="item">
                                    <img src={slide1} alt="" />
                                    <div className="lg-tag">
                                        <a href="#">Racing</a>
                                    </div>
                                    <h4>
                                        <a href="#">
                                            Vertical Games <span>Daily</span>
                                        </a>
                                    </h4>
                                    <p>
                                        entry fee : <span>free</span>
                                    </p>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="item">
                                    <img src={slide2} alt="" />
                                    <div className="lg-tag">
                                        <a href="#">Zombie</a>
                                    </div>
                                    <h4>
                                        <a href="#">
                                            Hunter Kill <span>Daily</span>
                                        </a>
                                    </h4>
                                    <p>
                                        entry fee : <span>free</span>
                                    </p>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="item">
                                    <img src={slide3} alt="" />
                                    <div className="lg-tag">
                                        <a href="#">creative</a>
                                    </div>
                                    <h4>
                                        <a href="#">
                                            Vertical Games <span>Daily</span>
                                        </a>
                                    </h4>
                                    <p>
                                        entry fee : <span>free</span>
                                    </p>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="item">
                                    <img src={slide1} alt="" />
                                    <div className="lg-tag">
                                        <a href="#">racing</a>
                                    </div>
                                    <h4>
                                        <a href="#">
                                            Sky Walker <span>Daily</span>
                                        </a>
                                    </h4>
                                    <p>
                                        entry fee : <span>free</span>
                                    </p>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="item">
                                    <img src={slide2} alt="" />
                                    <div className="lg-tag">
                                        <a href="#">Zombie</a>
                                    </div>
                                    <h4>
                                        <a href="#">
                                            Vertical Games <span>Daily</span>
                                        </a>
                                    </h4>
                                    <p>
                                        entry fee : <span>free</span>
                                    </p>
                                </div>
                            </div>
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
};
