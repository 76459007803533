import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import aboutImg from "../assets/images/third_about_img.png";
import { Nav } from "react-bootstrap";



export const AboutUs = () => {
    // Add your state or any other logic here if needed
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>

            <section className="about_wrapper about_page">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="aboutText">
                                <h4>THEMEBEYOND</h4>
                                <h6>Oddman</h6>
                                <h3>
                                    About <span>Us</span>
                                </h3>
                                <p>
                                    The Legend of Zelda: Skyward Sword is an action-adventure game
                                    developed and published by Nintendo for the Wii. The six
                                    teenth mainline entry in The Legend of Zelda series.
                                </p>
                                <Nav.Link href="/contact">
                                    <div className="common-btn">
                                        <div
                                            className="svg-icon"
                                            id="svg-2"
                                            data-svg-icon="assets/img/icons/shape02.svg">
                                            <svg
                                                preserveAspectRatio="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 152.29 47"
                                                width="100%"
                                                height="100%">
                                                <path
                                                    className="cls-1"
                                                    d="M1.13,24l12-23h125l13,22-13,23h-125Z"
                                                    style={{
                                                        strokeDasharray: "353, 355",
                                                        strokeDashoffset: 0,
                                                    }}></path>
                                            </svg>
                                            <span>Contact Us</span>
                                        </div>
                                    </div>
                                </Nav.Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <img src={aboutImg} alt="aboutImg" />
                        </Col>
                    </Row>

                </Container>
            </section>



        </>
    );
};
