import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

//internal import
import { AdminContext } from "../context/AdminContext";
import UserServices from "../services/UserServices";
import { notifyError, notifySuccess } from "../utils/toast";
import { removeSetting } from "../reduxStore/slice/settingSlice";

const useLoginSubmit = () => {
  const reduxDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AdminContext);
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = ({ name, email, verifyEmail, password, userName }) => {

    setLoading(true);
    const cookieTimeOut = 0.5;
    // return;

    if (location.pathname === "/login") {
      reduxDispatch(removeSetting("globalSetting"));
      UserServices.userLogin({ email, password, deviceToken: '', deviceType: "web" })
        .then((res) => {
          if (res) {
            setLoading(false);
            notifySuccess("Login Success!");
            dispatch({ type: "USER_LOGIN", payload: res });
            Cookies.set("adminInfo", JSON.stringify(res), {
              expires: cookieTimeOut,
              sameSite: "None",
              secure: true,
            });

            history.replace("/");
          }
        })
        .catch((err) => {
          notifyError(err?.response?.data?.message || err?.message);
          // notifyError(err ? err?.response?.data?.message : err?.message);
          setLoading(false);
        });
    }

    if (location.pathname === "/signup") {
      UserServices.userSignup({ name, email, password, userName, deviceToken: '', deviceType: "web" })
        .then((res) => {
          if (res) {
            setLoading(false);
            notifySuccess("Register Success!");
            dispatch({ type: "USER_LOGIN", payload: res });
            Cookies.set("adminInfo", JSON.stringify(res), {
              expires: cookieTimeOut,
              sameSite: "None",
              secure: true,
            });
            history.replace("/login");
          }
        })
        .catch((err) => {
          notifyError(err?.response?.data?.message || err?.message);
          // notifyError(err ? err?.response?.data?.message : err?.message);
          setLoading(false);
        });
    }

    // if (location.pathname === "/forgotpassword") {
    //   UserServices.forgetPassword({ verifyEmail })
    //     .then((res) => {
    //       setLoading(false);
    //       notifySuccess(res.message);
    //     })
    //     .catch((err) => {
    //       setLoading(false);
    //       notifyError(err?.response?.data?.message || err?.message);
    //       // notifyError(err ? err?.response?.data?.message : err?.message);
    //     });
    // }
  };
  return {
    onSubmit,
    register,
    handleSubmit,
    errors,
    loading,
  };
};

export default useLoginSubmit;
