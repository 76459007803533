import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import LoginImage from "../assets/images/login.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from 'react-bootstrap/Nav';
import useLoginSubmit from "../hooks/useLoginSubmit";
import { Link } from "react-router-dom";
import InputArea from "../components/InputArea";
import LabelArea from "../components/LabelArea";
import Error from "../components/Error";
export const Login = () => {
  const { onSubmit, register, handleSubmit, errors, loading } =
  useLoginSubmit();


  return (
    <div className="login_form  mx-auto mt-5 mb-0">
      <Container className="main-container">
        <Row>
          <Col md={6}>
            <img src={LoginImage} alt="login" />
          </Col>
          <Col md={6} className="align-content-center">
          <div className="login_heading">
                <h3>Sign In</h3>
            </div>
            <Form className="inner-form" onSubmit={handleSubmit(onSubmit)}>
                  <LabelArea label="Email" />
                  <InputArea
                    register={register}
                    defaultValue=""
                    label="Email"
                    name="email"
                    type="email"
                    autoComplete="username"
                    placeholder="john@doe.com"
                  />
                  <Error errorName={errors.email} />
                  <div className="mt-6"></div>
                  <LabelArea label="Password" />
                  <InputArea
                    register={register}
                    defaultValue=""
                    label="Password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    placeholder="***************"
                  />
                  <Error errorName={errors.password} />

                  <Button variant="primary" type="submit" className="my-4" disabled={loading}>
                Login
              </Button>
              <p className="mt-4">
                <Link
                  className="text-sm font-medium text-red-400 dark:text-red-300 hover:underline"
                  to="/signup"
                >
                  {("Create Account")}
                </Link>
              </p>
                  
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
