import React from "react";

const LabelArea = ({ label,forId ,className }) => {
  return (
    <label htmlFor={forId} className={`col-span-4 sm:col-span-2 font-medium text-sm ${className}`}>
      {label}
    </label>
  );
};

export default LabelArea;
