import './App.css';
import './assets/css/style.css';
import './assets/fonts/stylesheet.css';
import React from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./reduxStore/store";
import { Home } from './pages/Home';
import MainLayout from './components/MainLayout';
import LoginLayout from './components/LoginLayout';
import { Gaming } from './pages/Gaming';
import { LiveStreaming } from './pages/LiveStreaming';
import { AboutUs } from './pages/AboutUs';
import { ContactUs } from './pages/ContactUs';
import { LatestReleases } from './pages/LatestReleases';
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';
import { ForgetPassword } from './pages/ForgetPassword';
import { ResetPassword } from './pages/ResetPassword';
import { AdminProvider } from './context/AdminContext';
import PrivateRoute from './components/login/PrivateRoute';

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistor = persistStore(store);


function App() {
  return (
    <AdminProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />
          <Router>
            <Switch>
              <Route path="/login" render={() => <LoginLayout><Login /></LoginLayout>} />
              <Route path="/signup" render={() => <LoginLayout><Signup /></LoginLayout>} />
              <Route path="/forgotpassword" render={() => <LoginLayout><ForgetPassword /></LoginLayout>} />
              <Route path="/resetpassword" render={() => <LoginLayout><ResetPassword /></LoginLayout>} />

              <Route exact path="/"> <MainLayout><Home /></MainLayout></Route>
              <Route exact path="/gaming"> <MainLayout><Gaming /></MainLayout></Route>
              <Route exact path="/liveStreaming"> <MainLayout><LiveStreaming /></MainLayout></Route>
              <Route exact path="/latestReleases"> <MainLayout><LatestReleases /></MainLayout></Route>
              <Route exact path="/about"> <MainLayout><AboutUs /></MainLayout></Route>
              <Route exact path="/contact"> <MainLayout><ContactUs /></MainLayout></Route>

              {/* <PrivateRoute exact path="/">
                <MainLayout><Home /></MainLayout>
              </PrivateRoute>
              <PrivateRoute path="/gaming">
                <MainLayout><Gaming /></MainLayout>
              </PrivateRoute>
              <PrivateRoute path="/liveStreaming">
                <MainLayout><LiveStreaming /></MainLayout>
              </PrivateRoute>
              <PrivateRoute path="/latestReleases">
                <MainLayout><LatestReleases /></MainLayout>
              </PrivateRoute>
              <PrivateRoute path="/about">
                <MainLayout><AboutUs /></MainLayout>
              </PrivateRoute>
              <PrivateRoute path="/contact">
                <MainLayout><ContactUs /></MainLayout>
              </PrivateRoute> */}

              {/* Redirect from root to login */}
              <Redirect exact from="/" to="/login" />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </AdminProvider>
  );
}

export default App;
