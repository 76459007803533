import React, { } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import LoginImage from "../assets/images/login.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useLoginSubmit from "../hooks/useLoginSubmit";
import LabelArea from "../components/LabelArea";
import InputArea from "../components/InputArea";
import Error from "../components/Error";
import { Link } from "react-router-dom";

export const Signup = () => {
  const { onSubmit, register, handleSubmit, errors, loading } =
    useLoginSubmit();

  return (
    <div className="login_form  mx-auto mt-5 mb-0" id="signup">
      <Container className="main-container">
        <Row>
          <Col md={6}>
            <img src={LoginImage} alt="login" />
          </Col>
          <Col md={6} className="align-content-center">
            <div className="login_heading">
              <h3>Create Account</h3>
            </div>
            <Form className="inner-form" onSubmit={handleSubmit(onSubmit)}>
              <>
                <LabelArea label="Name" />
                <InputArea
                  register={register}
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Name"
                />
                <Error errorName={errors.name} /></>
              <>
                <LabelArea label="User Name" />
                <InputArea
                  register={register}
                  label="User Name"
                  name="userName"
                  type="text"
                  placeholder="userName"
                />
                <Error errorName={errors.userName} />
              </>
              <>
                <LabelArea label="Email" />
                <InputArea
                  register={register}
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="john@doe.com"
                />
                <Error errorName={errors.email} /></>
              <>
                <LabelArea label="Password" />
                <InputArea
                  register={register}
                  label="Password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  placeholder="***************"
                />
                <Error errorName={errors.password} /></>
              <Button variant="primary" type="submit" className="my-4" disabled={loading}>
                Create Account
              </Button>
              <p className="mt-4">
                <Link
                  className="text-sm font-medium text-red-400 dark:text-red-300 hover:underline"
                  to="/login"
                >
                  {("Already have an account? Login")}
                </Link>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
