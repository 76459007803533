import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import bannerImg from "../assets/images/slider_img01.png";
import aboutImg from "../assets/images/third_about_img.png";
import Titlebar from "../assets/images/title_bar02.png";
import slide1 from "../assets/images/latest_games_thumb01.jpg";
import slide2 from "../assets/images/latest_games_thumb02.jpg";
import slide3 from "../assets/images/latest_games_thumb03.jpg";
import cta from "../assets/images/cta_img.png";
import stream1 from "../assets/images/stream_thumb01.jpg";
import stream2 from "../assets/images/stream_thumb02.jpg";
import stream3 from "../assets/images/stream_thumb03.jpg";
import item1 from "../assets/images/s_product_img01.jpg";
import item2 from "../assets/images/s_product_img02.jpg";
import item3 from "../assets/images/s_product_img03.jpg";
import item4 from "../assets/images/s_product_img04.jpg";
import Play from "../assets/images/w_play.png";
import Slider from "react-slick";

export const Gaming = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <> <section className="gaming_wrapper gaming_accessories">
            <Container>
                <Row className="align-items-center mb-4">
                    <Col md={12} className="text-center main_heading mb-5">
                        <h3>
                            GAMING PRODUCTS <span>CORNER</span>
                        </h3>
                        <p>
                            Compete with 100 players on a remote island for winner takes showdown <br />known issue where certain skin strategic
                        </p>
                        <img src={Titlebar} />
                    </Col>
                    <Col md={12}>
                        <Slider {...settings} className="main_slider">
                            <div className="slider-item">
                                <div className="item">
                                    <img src={item1} alt="" />

                                    <h4>
                                        <a href="#">
                                            Women Black T-shirt
                                        </a>
                                    </h4>
                                    <p>
                                        <strong>$29.00</strong>
                                    </p>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="item">
                                    <img src={item2} alt="" />

                                    <h4>
                                        <a href="#">
                                            Gears 5 Xbox Controller
                                        </a>
                                    </h4>
                                    <p>
                                        <strong>$29.00</strong>
                                    </p>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="item">
                                    <img src={item3} alt="" />
                                    <h4>
                                        <a href="#">
                                            GeForce RTX 2070
                                        </a>
                                    </h4>
                                    <p>
                                        <strong>$50.00</strong>
                                    </p>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="item">
                                    <img src={item4} alt="" />

                                    <h4>
                                        <a href="#">
                                            Virtual Reality Smiled
                                        </a>
                                    </h4>
                                    <p>
                                        <strong>$40.00</strong>
                                    </p>
                                </div>
                            </div>
                            <div className="slider-item">
                                <div className="item">
                                    <img src={item1} alt="" />

                                    <h4>
                                        <a href="#">
                                            Vertical Games <span>Daily</span>
                                        </a>
                                    </h4>
                                    <p>
                                        entry fee : <span>free</span>
                                    </p>
                                </div>
                            </div>
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section></>
    );
};
