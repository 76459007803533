import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "../assets/images/oddman-logo.png";
import { AdminContext } from '../context/AdminContext';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { notifySuccess } from '../utils/toast';


export const CustomNavbar = () => {
  const {dispatch } = useContext(AdminContext);
  const adminInfo = Cookies.get("adminInfo");
  const history = useHistory();

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT" });
    Cookies.remove("adminInfo");
    history.push('/'); 
    notifySuccess("Successfully Logout!")
  };

  return (
    <header className="navbar">
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} alt='logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/latestReleases">Latest Releases</Nav.Link>
              <Nav.Link href="/liveStreaming">Live Streaming</Nav.Link>
              <Nav.Link href="/gaming">Gaming Accessories</Nav.Link>
              {/* <Nav.Link href="/contact">Contact Us</Nav.Link> */}
            </Nav>
            <Nav>
              {adminInfo ? (
                <Nav.Link onClick={handleLogOut}>
                  <div className="svg-icon" id="svg-2" data-svg-icon="assets/img/icons/shape02.svg">
                    <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.29 47" width="100%" height="100%">
                      <path className="cls-1" d="M1.13,24l12-23h125l13,22-13,23h-125Z" style={{ strokeDasharray: '353, 355', strokeDashoffset: 0 }}></path>
                    </svg>
                    <span>Logout</span>
                  </div>
                </Nav.Link>
              ) : (
                <Nav.Link href="/login">
                  <div className="svg-icon" id="svg-2" data-svg-icon="assets/img/icons/shape02.svg">
                    <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.29 47" width="100%" height="100%">
                      <path className="cls-1" d="M1.13,24l12-23h125l13,22-13,23h-125Z" style={{ strokeDasharray: '353, 355', strokeDashoffset: 0 }}></path>
                    </svg>
                    <span>Sign In</span>
                  </div>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};