// src/layouts/LoginLayout.js
import React from 'react';

const LoginLayout = ({ children }) => {
  return (
    <div className="login-layout">
      {children}
    </div>
  );
};

export default LoginLayout;
