import React, { } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../assets/images/oddman-logo.png";

export const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="footer-widget mb-50">
                                <div className="footer-logo mb-35">
                                    <a href="index.html"><img src={Logo} alt="" /></a>
                                </div>
                                <div className="footer-text">
                                    <p>Gemas marketplace the relase etras thats sheets continig passag.</p>

                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="footer-widget mb-50">
                                <div className="fw-title mb-35">
                                    <h5>Products</h5>
                                </div>
                                <div className="fw-link">
                                    <ul>
                                        <li><a href="#">Graphics (26)</a></li>
                                        <li><a href="#">Backgrounds (11)</a></li>
                                        <li><a href="#">Fonts (9)</a></li>
                                        <li><a href="#">Music (3)</a></li>
                                        <li><a href="#">Photography (3)</a></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="footer-widget mb-50">
                                <div className="fw-title mb-35">
                                    <h5>Contact us</h5>
                                </div>
                                =                                <div className="footer-contact">
                                    <ul>
                                        <li><i className="fas fa-map-marker-alt"></i> <span>Address : </span>PO Box W75 Street
                                            lan West new queens</li>
                                        <li><i className="fas fa-headphones"></i> <span>Phone : </span>+24 1245 654 235</li>
                                        <li><i className="fas fa-envelope-open"></i><span>Email : </span>info@exemple.com</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="copyright">
                    <p>Copyright © 2024 oddman All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
};
