import React, { } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import bannerImg from "../assets/images/slider_img01.png";
import aboutImg from "../assets/images/third_about_img.png";
import Titlebar from "../assets/images/title_bar02.png";
import slide1 from "../assets/images/latest_games_thumb01.jpg";
import slide2 from "../assets/images/latest_games_thumb02.jpg";
import slide3 from "../assets/images/latest_games_thumb03.jpg";
import cta from "../assets/images/cta_img.png";
import stream1 from "../assets/images/stream_thumb01.jpg";
import stream2 from "../assets/images/stream_thumb02.jpg";
import stream3 from "../assets/images/stream_thumb03.jpg";
import item1 from "../assets/images/s_product_img01.jpg";
import item2 from "../assets/images/s_product_img02.jpg";
import item3 from "../assets/images/s_product_img03.jpg";
import item4 from "../assets/images/s_product_img04.jpg";
import Play from "../assets/images/w_play.png";
import Slider from "react-slick";
import { Nav } from "react-bootstrap";
export const Home = () => {
  // Add your state or any other logic here if needed
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="banner">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="banner-text">
                <h6>world gaming</h6>
                <h2>
                  Create <span>Manage</span> Matches
                </h2>
                <p>
                  Find technology or people for digital projects in public
                  sector and Find an individual specialist develope researcher.
                </p>
                <Nav.Link href="/contact">
                <div className="common-btn">
                  <div
                    className="svg-icon"
                    id="svg-2"
                    data-svg-icon="assets/img/icons/shape02.svg">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 152.29 47"
                      width="100%"
                      height="100%">
                      <path
                        className="cls-1"
                        d="M1.13,24l12-23h125l13,22-13,23h-125Z"
                        style={{
                          strokeDasharray: "353, 355",
                          strokeDashoffset: 0,
                        }}></path>
                    </svg>
                    <span>Contact Us</span>
                  </div>
                </div>
                </Nav.Link>
              </div>
            </Col>
            <Col md={6}>
              <div className="bannerImg">
                <img src={bannerImg} alt="bannerImg" />
              </div>
            </Col>
          </Row>
        </Container>
        {/* <ToastContainer /> */}
      </section>
      <section className="about_wrapper">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="aboutText">
                <h4>THEMEBEYOND</h4>
                <h6>Oddman</h6>
                <h3>
                  About <span>Us</span>
                </h3>
                <p>
                  The Legend of Zelda: Skyward Sword is an action-adventure game
                  developed and published by Nintendo for the Wii. The six
                  teenth mainline entry in The Legend of Zelda series.
                </p>
                <Nav.Link href="/contact">
                <div className="common-btn">
                  <div
                    className="svg-icon"
                    id="svg-2"
                    data-svg-icon="assets/img/icons/shape02.svg">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 152.29 47"
                      width="100%"
                      height="100%">
                      <path
                        className="cls-1"
                        d="M1.13,24l12-23h125l13,22-13,23h-125Z"
                        style={{
                          strokeDasharray: "353, 355",
                          strokeDashoffset: 0,
                        }}></path>
                    </svg>
                    <span>Contact Us</span>
                  </div>
                </div>
                </Nav.Link>
              </div>
            </Col>
            <Col md={6}>
              <img src={aboutImg} alt="aboutImg" />
            </Col>
          </Row>
          <Row className="align-items-center mt-5">
            <Col md={12} className="text-center main_heading mb-0 mt-5">
              <h3>
                RELEASED <span>GAMES</span>
              </h3>
              <p>
                Compete with 100 players on a remote island for winner takes
                showdown <br />
                known issue where certain skin strategic
              </p>
              <img src={Titlebar} />
            </Col>
            <Col md={12}>
              <Slider {...settings} className="main_slider mt-5">
                <div className="slider-item">
                  <div className="item">
                    <img src={slide1} alt="" />
                    <div className="lg-tag">
                      <a href="#">Racing</a>
                    </div>
                    <h4>
                      <a href="#">
                        Vertical Games <span>Daily</span>
                      </a>
                    </h4>
                    <p>
                      entry fee : <span>free</span>
                    </p>
                  </div>
                </div>
                <div className="slider-item">
                  <div className="item">
                    <img src={slide2} alt="" />
                    <div className="lg-tag">
                      <a href="#">Zombie</a>
                    </div>
                    <h4>
                      <a href="#">
                        Hunter Kill <span>Daily</span>
                      </a>
                    </h4>
                    <p>
                      entry fee : <span>free</span>
                    </p>
                  </div>
                </div>
                <div className="slider-item">
                  <div className="item">
                    <img src={slide3} alt="" />
                    <div className="lg-tag">
                      <a href="#">creative</a>
                    </div>
                    <h4>
                      <a href="#">
                        Vertical Games <span>Daily</span>
                      </a>
                    </h4>
                    <p>
                      entry fee : <span>free</span>
                    </p>
                  </div>
                </div>
                <div className="slider-item">
                  <div className="item">
                    <img src={slide1} alt="" />
                    <div className="lg-tag">
                      <a href="#">racing</a>
                    </div>
                    <h4>
                      <a href="#">
                        Sky Walker <span>Daily</span>
                      </a>
                    </h4>
                    <p>
                      entry fee : <span>free</span>
                    </p>
                  </div>
                </div>
                <div className="slider-item">
                  <div className="item">
                    <img src={slide2} alt="" />
                    <div className="lg-tag">
                      <a href="#">Zombie</a>
                    </div>
                    <h4>
                      <a href="#">
                        Vertical Games <span>Daily</span>
                      </a>
                    </h4>
                    <p>
                      entry fee : <span>free</span>
                    </p>
                  </div>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="cta">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <img src={cta} alt="cta" />
            </Col>
            <Col md={6}>
              <h3>
                WORLDS MEET <span>REAL</span>
              </h3>
              <p>
                Compete with 100 player on a remote island for winner known
                issue where certain strategic
              </p>
              <img className="white_fillter" src={Titlebar} />
              <Nav.Link href="/contact">
              <div className="common-btn">
                <div
                  className="svg-icon"
                  id="svg-2"
                  data-svg-icon="assets/img/icons/shape02.svg">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 152.29 47"
                    width="100%"
                    height="100%">
                    <path
                      className="cls-1"
                      d="M1.13,24l12-23h125l13,22-13,23h-125Z"
                      style={{
                        strokeDasharray: "353, 355",
                        strokeDashoffset: 0,
                      }}></path>
                  </svg>
                  <span>Contact Us</span>
                </div>
              </div>
              </Nav.Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="live-streaming-area">
        <Container>
          <Row>
            <Col>
              <div className="hf-section-title mb-50">
                <h4 className="title">live streaming</h4>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={8}>
              <div className="live-stream-item mb-30">
                <div className="thumb">
                  <img src={stream1} alt="" className="main-img"/>
                  <a href="#" className="popup-video">
                    <img src={Play} alt="" />
                  </a>
                </div>
                <div className="content">
                  <div className="live-stream-meta">
                    <ul>
                      <li className="cat">
                        <a href="#">CRICKET</a>
                      </li>
                      <li>3 months ago</li>
                    </ul>
                  </div>
                  <h4 className="title">
                    <a href="#">
                      Ditched The Naked Chicken Chalupa Here’s To Make
                    </a>
                  </h4>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <div className="live-stream-item style-two mb-30">
                    <div className="thumb">
                      <img src={stream2} alt="" />
                    </div>
                    <div className="content">
                      <a href="#" className="popup-video">
                        <img src={Play} alt="" />
                      </a>
                      <div className="live-stream-meta">
                        <ul>
                          <li className="cat">
                            <a href="#">sports</a>
                          </li>
                          <li>3 months ago</li>
                        </ul>
                      </div>
                      <h4 className="title">
                        <a href="#">House last week that move would Inject</a>
                      </h4>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="live-stream-item style-two mb-30">
                    <div className="thumb">
                      <img src={stream3} alt="" />
                    </div>
                    <div className="content">
                      <a
                        href="https://www.youtube.com/watch?v=ssrNcwxALS4"
                        className="popup-video">
                        <img src="img/icon/w_play.png" alt="" />
                      </a>
                      <div className="live-stream-meta">
                        <ul>
                          <li className="cat">
                            <a href="#">sports</a>
                          </li>
                          <li>3 months ago</li>
                        </ul>
                      </div>
                      <h4 className="title">
                        <a href="#">Twas the night before in the NFL</a>
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="gaming_wrapper">
        <Container>
          <Row className="align-items-center mb-4">
            <Col md={12} className="text-center main_heading mb-5">
              <h3>
              GAMING PRODUCTS <span>CORNER</span>
              </h3>
              <p>
              Compete with 100 players on a remote island for winner takes showdown <br/>known issue where certain skin strategic
              </p>
              <img src={Titlebar} />
            </Col>
            <Col md={12}>
              <Slider {...settings} className="main_slider">
                <div className="slider-item">
                  <div className="item">
                    <img src={item1} alt="" />
                 
                    <h4>
                      <a href="#">
                      Women Black T-shirt 
                      </a>
                    </h4>
                    <p>
                     <strong>$29.00</strong>
                    </p>
                  </div>
                </div>
                <div className="slider-item">
                  <div className="item">
                    <img src={item2} alt="" />
                    
                    <h4>
                      <a href="#">
                      Gears 5 Xbox Controller
                      </a>
                    </h4>
                    <p>
                     <strong>$29.00</strong>
                    </p>
                  </div>
                </div>
                <div className="slider-item">
                  <div className="item">
                    <img src={item3} alt="" />
                    <h4>
                      <a href="#">
                      GeForce RTX 2070
                      </a>
                    </h4>
                    <p>
                     <strong>$50.00</strong>
                    </p>
                  </div>
                </div>
                <div className="slider-item">
                  <div className="item">
                    <img src={item4} alt="" />
                 
                    <h4>
                      <a href="#">
                      Virtual Reality Smiled
                      </a>
                    </h4>
                    <p>
                     <strong>$40.00</strong>
                    </p>
                  </div>
                </div>
                <div className="slider-item">
                  <div className="item">
                    <img src={item1} alt="" />
                   
                    <h4>
                      <a href="#">
                        Vertical Games <span>Daily</span>
                      </a>
                    </h4>
                    <p>
                      entry fee : <span>free</span>
                    </p>
                  </div>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
        </section>
    </>
  );
};
